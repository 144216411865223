<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-card uk-card-default ">
            <div class="uk-modal-body">
                    <div class="uk-grid">
                        <div class="uk-width-1-2">
                            <div class="uk-margin">
                                <label class="uk-form-label">Nama Bisnis <b class="uk-text-danger">*</b></label>
                                <input
                                    class="uk-input"
                                    :class="{'uk-form-danger': errors.has('name')}"
                                    name="name"
                                    type="text"
                                    v-model="form.name"
                                    v-validate="'required'"
                                    placeholder="PT Serba Bisa">
                                <span class="uk-text-small uk-text-danger" v-show="errors.has('name')">{{ errors.first('name') }}</span>
                            </div>
                            <div class="uk-margin">
                                <label class="uk-form-label">Email <b class="uk-text-danger">*</b></label>
                                <input
                                    class="uk-input"
                                    :class="{'uk-form-danger': errors.has('email')}"
                                    name="email"
                                    type="email"
                                    v-model="form.email"
                                    v-validate="'required'"
                                    placeholder="jonie@robin.id">
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('email')">{{ errors.first('email') }}</span>
                            </div>
                            <div class="uk-margin">
                                <label class="uk-form-label">No Telepon 1 <b class="uk-text-danger">*</b></label>
                                <input
                                    class="uk-input"
                                    :class="{'uk-form-danger': errors.has('phone_number')}"
                                    name="phone_number"
                                    type="number"
                                    v-model="form.phone_number"
                                    v-validate="'required'"
                                    placeholder="08221398273">
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('phone_number')">{{ errors.first('phone_number') }}</span>
                            </div>
                            <div class="uk-margin">
                                <label class="uk-form-label">No Telepon 2</label>
                                <input
                                    class="uk-input"
                                    type="number"
                                    v-model="form.phone_number_second"
                                    placeholder="08221398273">
                            </div>
                            <div class="uk-margin">
                                <label class="uk-form-label">Sumber <b class="uk-text-danger">*</b></label>
                                <input
                                    class="uk-input"
                                    :class="{'uk-form-danger': errors.has('sumber')}"
                                    name="type"
                                    type="text"
                                    v-model="form.type"
                                    v-validate="'required'"
                                    placeholder="Admin">
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('sumber')">{{ errors.first('sumber') }}</span>
                            </div>
                        </div>
                        <div class="uk-width-1-2">
                            <div class="uk-grid">
                                <div class="uk-width">
                                    <div class="uk-margin">
                                        <div class="uk-form-controls">
                                            <label class="uk-form-label">Alamat (Kota) <b class="uk-text-danger">*</b></label>
                                            <v-select
                                                :options="City"
                                                :filterable="false"
                                                :class="{'uk-form-danger': errors.has('address')}"
                                                v-model="form.address"
                                                name="address"
                                                placeholder="Cari.."
                                                @search="query => search = query"
                                            >
                                            </v-select>
                                            <span class="uk-text-small uk-text-danger" v-show="errors.has('address')">{{ errors.first('address') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="uk-margin">
                                <label class="uk-form-label">Posisi yang dibuka <b class="uk-text-danger">*</b></label>
                                <input
                                    class="uk-input"
                                    :class="{'uk-form-danger': errors.has('title')}"
                                    name="title"
                                    type="text"
                                    v-model="form.title"
                                    v-validate="'required'"
                                    placeholder="Kasir">
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('title')">{{ errors.first('title') }}</span>
                            </div>
                            <div class="uk-margin">
                                <div class="uk-form-controls">
                                    <label class="uk-form-label">Status <b class="uk-text-danger">*</b></label>
                                    <select
                                        class="uk-select"
                                        :class="{'uk-form-danger': errors.has('status')}"
                                        name="status"
                                        v-validate="'required'"
                                        id="form-horizontal-select"
                                        v-model="form.status"
                                    >
                                        <option :value="''">Select status</option>
                                        <option v-for="(data, i) in job_datas.status" :key="i" :value="data.value">{{data.name}}</option>
                                    </select>
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('status')">{{ errors.first('status') }}</span>
                                </div>
                            </div>
                            <div class="uk-margin">
                                <div class="uk-form-controls">
                                    <label class="uk-form-label">Komentar </label>
                                    <textarea
                                        class="uk-textarea"
                                        name="comment"
                                        type="text"
                                        v-model="form.comment"
                                        placeholder="Keahlian dan Pengalaman"/>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <div class="uk-modal-footer uk-text-right">
                <button class="uk-button uk-button-default uk-margin-right" type="button" @click="toBack" :disabled="isLoading">Back</button>
                <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
                <button v-else class="uk-button uk-button-primary" type="button" @click="saveJob">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import formatter from "@/utils/formatter";
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import axios from "axios";

export default {
    data() {
        return {
            form: {
                type: '',
                status: '',
                name: '',
                phone_number: '',
                phone_number_second: '',
                email: '',
                title: '',
                comment: '',
                address: ''
            },
            isLoading: false,
            city: [],
            limit: 50,
            search: ''
        };
    },
    components: {
        vSelect
    },
    computed: {
        ...mapGetters({
            job_admin: 'job_admin/job_admin',
            job_datas: 'job_admin/job_datas',
        }),
        filteredCity() {
            return this.city.filter(city => city.toLowerCase().includes(this.search));
        },
        City() {
            return this.filteredCity.slice(0, this.limit);
        }
    },
    async mounted() {
        await Promise.all([
            this.getJobDetail(this.$route.params.job_id),
            this.getJobData()
        ]);
        this.populateData();
        this.dataCity();
    },
    methods: {
        ...mapActions({
            getJobDetail: 'job_admin/getJobDetail',
            getJobData: 'job_admin/getJobData',
            updateJob: 'job_admin/updateJob'
        }),
        populateData() {
            this.form = {
                type: this.job_admin.type,
                status: this.job_admin.status,
                name: this.job_admin.name,
                phone_number: this.job_admin.phone_number,
                phone_number_second: this.job_admin.phone_number_second,
                email: this.job_admin.email,
                title: this.job_admin.title,
                comment: this.job_admin.comment,
                address: this.job_admin.address
            };
        },
        getDate(data) {
            return formatter.dateComplete(data);
        },
        toBack() {
            window.location.href = '/admin/job-admin';
        },
        async saveJob() {
            this.$validator.validateAll().then(async (success) => {
                if (success) {
                    if (this.$validator.errors.any()) return;
                    this.isLoading = true;
                    const response = await this.updateJob({jobId: this.$route.params.job_id, data: this.form});
                    if (response) {
                        this.isLoading = false;
                        this.$router.push('/admin/job-admin');
                    } else {
                        this.isLoading = false;
                    }
                }
            });
        },
        dataCity() {
            axios.get('/static/kota-kabupaten.json').then(res => {
                this.city = res.data;
            }).catch((err) => {
                console.error(err);
            });
        }
    }
};
</script>
